@import "breakpoint";
@import "settings";
@import "foundation/components/global"; // *always required
@import "foundation/components/grid";

.paragraphs-item-home-what-we-do {
  background: #f3f3f3;
  @include clearfix;
  border-bottom: 1px solid #f3f3f3;
  border-top: 1px solid #f3f3f3;

  padding: 30px 0 10px;
  @include breakpoint($desktop) {
    padding: 80px 0 20px;
  }

  .field-name-field-sub-paragraphs {
    @include clearfix;
    padding-top: 20px;

    .field-name-field-image {
      vertical-align: bottom;
      display: table-cell;
      @include breakpoint($desktop) {
        height: 180px;
      }
    }
  }

  .link-wrapper {
    text-decoration: none !important;
  }

  //h3 {
  //  padding-top: 15px;
  //  font-size: 30px;
  //}
  //
  //h3 {
  //  &:after {
  //    display: block;
  //    content: "";
  //    width: 20px;
  //    padding-top: 6px;
  //    border-bottom: 1px solid $color-gold;
  //    margin-bottom: 20px;
  //  }
  //}

  .field-name-field-sub-title {
    text-transform: uppercase;
    font-size: 16px;
  }

  .paragraphs-item-image-title-and-text {
    padding-bottom: 15px;
    @include breakpoint($desktop) {
      padding-right: 60px;
      width: 33%;
      float: left;
    }

    .field-name-field-title:after {
      display: block;
      content: "";
      width: 20px;
      border-bottom: 1px solid $color-gold;
      margin-top: 18px;
      margin-bottom: 20px;
    }
  }

  .minds-eye-home {
    background: white;
    @include clearfix;
    padding: 24px;
    position: relative;
    @include breakpoint($desktop) {
      height: 215px;
    }
    div:first-child {
      @include breakpoint($mobile) {
        text-align: center;
        margin-bottom: 20px;
      }
    }

    img {
      @include breakpoint($desktop) {
        position: absolute;
        margin: 0;
      }
    }
    p {
      font-size: 19px;
      margin-bottom: 0;

      @include breakpoint($desktop) {
        padding-right: 48px;
        margin-left: 200px;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
